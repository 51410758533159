<template>
    <div class="wrap">
        <div class="container">
            <div class="nav_bar">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/recruit' }">招聘信息</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ $route.query.edit ? '修改简历信息' : '完善简历信息'}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="form_area">
                <el-form :model="personalInfo" :rules="rules" ref="personalInfo" label-width="100px" class="form">
                    <div class="avatar_box">
                        <el-upload class="avatar-uploader" action="#" :show-file-list="false" :auto-upload="false"
                            :on-change="upload">
                            <img :src="
                                personalInfo.avatar
                                ? personalInfo.avatar
                                : require(`@/assets/img/defaultavatar.png`)
                            " class="avatar" />
                            <div class="edit_cover"></div>
                            <span class="edit_img">修改</span>
                        </el-upload>
                        <p class="tips">点击上传头像</p>
                    </div>
                    <el-form-item label="真实姓名" prop="memberName">
                        <el-input v-model="personalInfo.memberName" placeholder="请填写真实姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="mobile">
                        <el-input v-model="personalInfo.mobile" placeholder="请填写联系电话"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="personalInfo.email" placeholder="请填写邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="年龄" prop="birthday">
                        <el-date-picker v-model="personalInfo.birthday" type="date" value-format="yyyy-MM-dd"
                            placeholder="请选择出生日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="学历" prop="qualification">
                        <el-select v-model="personalInfo.qualification" placeholder="请选择学历">
                            <el-option v-for="item in qualificationList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="在职状态" prop="jobStatus">
                        <el-select v-model="personalInfo.jobStatus" placeholder="请选择在职状态">
                            <el-option v-for="item in jobStatusList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工作年限" prop="jobYear">
                        <el-input v-model="personalInfo.jobYear" placeholder="请填写工作年限"></el-input>
                    </el-form-item>
                    <el-form-item label="期望职位" prop="wishPost">
                        <el-input v-model="personalInfo.wishPost" placeholder="请填写期望职位"></el-input>
                    </el-form-item>
                    <el-form-item label="工作薪资" prop="wishSalary">
                        <el-select v-model="personalInfo.wishSalary" placeholder="请选择期望薪资">
                            <el-option v-for="item in wishSalaryList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工作城市" prop="jobCity">
                        <el-cascader v-model="personalInfo.jobCity" :options="citycList" :props="{ value: 'label' }"
                            filterable placeholder="请选择期望的工作城市">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="期望行业" prop="wishIndustry">
                        <el-cascader v-model="personalInfo.wishIndustry" :options="industryList"
                            :props="{ value: 'label' }" filterable placeholder="请选择期望的行业">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="工作经历" class="experience">
                        <div class="right">
                            <span class="tips">(点击添加经历)</span>
                            <i class="el-icon-circle-plus-outline" @click="addWorkExperience"></i>
                        </div>
                    </el-form-item>
                    <div class="one_experience" v-for="(item, idx) in personalInfo.jobHistory" :key="idx">
                        <i class="el-icon-delete" @click="delWorkExperience(idx)"></i>
                        <el-form-item label="公司名称">
                            <el-input v-model="item.companyName" placeholder="请填写公司名称"></el-input>
                        </el-form-item>
                        <el-form-item label="在职时间" class="time_range">
                            <el-date-picker class="time_picker" v-model="item.startTime" type="month"
                                value-format="yyyy/MM" placeholder="选择入职时间">
                            </el-date-picker>
                            <span class="separator">-</span>
                            <el-date-picker class="time_picker" v-model="item.endTime" type="month"
                                value-format="yyyy/MM" placeholder="选择离职时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="职位名称">
                            <el-input v-model="item.post" placeholder="请填写职位名称"></el-input>
                        </el-form-item>
                    </div>

                    <el-form-item label="教育背景" class="experience">
                        <div class="right">
                            <span class="tips">(点击添加经历)</span>
                            <i class="el-icon-circle-plus-outline" @click="addEducationExperience"></i>
                        </div>
                    </el-form-item>
                    <div class="one_experience" v-for="(item, idx) in personalInfo.eduHistory" :key="'eduHistory-'+idx">
                        <i class="el-icon-delete" @click="delEducationExperience(idx)"></i>
                        <el-form-item label="学校名称">
                            <el-input v-model="item.schoolName" placeholder="请填写学校名称"></el-input>
                        </el-form-item>
                        <el-form-item label="在校时间" class="time_range">
                            <el-date-picker class="time_picker" v-model="item.startTime" type="month"
                                value-format="yyyy/MM" placeholder="选择入校时间">
                            </el-date-picker>
                            <span class="separator">-</span>
                            <el-date-picker class="time_picker" v-model="item.endTime" type="month"
                                value-format="yyyy/MM" placeholder="选择毕业时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="专业名称">
                            <el-input v-model="item.major" placeholder="请填写专业名称"></el-input>
                        </el-form-item>
                    </div>

                    <el-form-item label="自我评价" prop="selfEvaluation" style="margin-top:20px">
                        <el-input type="textarea" v-model="personalInfo.selfEvaluation" resize="none"
                            :autosize="{ minRows: 3 }" placeholder="请填写自我评价"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="submit_btn" type="primary" @click="submitForm('personalInfo')">提交</el-button>
                        <el-button class="reset_btn" @click="resetForm('personalInfo')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getResume,
        editResume
    } from '@/api/recruit'
    import upload from "@/utils/uploadMethod";
    import areaData from "@/data/area-data-min.js";
    import industryData from "@/data/industry-data-min.js";
    export default {
        name: "personalData",
        data() {
            return {
                personalInfo: {
                    avatar: "",
                    memberName: "",
                    mobile: "",
                    email: "",
                    birthday: "",
                    qualification: "",
                    jobStatus: "",
                    jobYear: "",
                    wishPost: "",
                    jobCity: "",
                    wishSalary: "",
                    wishIndustry: "",
                    eduHistory: [],
                    jobHistory: [],
                    selfEvaluation: "",
                },
                rules: {
                    memberName: {
                        required: true,
                        message: "请输入真实姓名",
                        trigger: "blur",
                    },
                    mobile: {
                        required: true,
                        message: "请输入联系电话",
                        trigger: "blur",
                    },
                    birthday: {
                        required: true,
                        message: "请选择出生日期",
                        trigger: "blur",
                    },
                    qualification: {
                        required: true,
                        message: "请选择学历水平",
                        trigger: "blur",
                    },
                    jobStatus: {
                        required: true,
                        message: "请选择在职状态",
                        trigger: "blur",
                    },
                    jobYear: {
                        required: true,
                        message: "请填写工作年限",
                        trigger: "blur",
                    },
                    wishPost: {
                        required: true,
                        message: "请填写期望职位",
                        trigger: "blur",
                    },
                    wishSalary: {
                        required: true,
                        message: "请选择期望薪资",
                        trigger: "blur",
                    },
                    jobCity: {
                        required: true,
                        message: "请选择期望的工作地点",
                        trigger: "blur",
                    },
                    wishIndustry: {
                        required: true,
                        message: "请选择期望的行业",
                        trigger: "blur",
                    },
                },
                qualificationList: [
                    "初中及以下",
                    "中专/中技",
                    "高中",
                    "大专",
                    "本科",
                    "硕士",
                    "博士",
                ],
                jobStatusList: ["离职-随时到岗", "在职-考虑机会", "毕业生"],
                wishSalaryList: ["4-6K", "6-8K", "8-10K", "10-15K", "15-20K", "20K以上"],
                citycList: areaData,
                industryList: industryData,
            };
        },
        created() {
            if (this.$route.query.edit == 1) this.getMyResume();
        },
        methods: {
            getMyResume() {
                this.$loading.show();
                getResume().then(res => {
                    this.$loading.hide();
                    if (res.code == 200) {
                        res.data.jobCity = res.data.jobCity.split("-");
                        res.data.wishIndustry = res.data.wishIndustry.split("-");
                        this.personalInfo = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            //上传头像
            async upload(file) {
                let option = {
                    fileType: ["image/jpeg", "image/png", "image/bmp"],
                    isVerificationType: true,
                    isVerificationSize: true,
                    fileSize: 5,
                };
                const fileUrl = await upload(file, option);
                this.personalInfo.avatar = fileUrl;
            },
            // 添加工作经历
            addWorkExperience() {
                this.personalInfo.jobHistory.push({
                    companyName: '',
                    startTime: '',
                    endTime: '',
                    post: ''
                })
            },
            // 删除工作经历
            delWorkExperience(idx) {
                // console.log(idx)
                this.$confirm('确定要删除吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    this.personalInfo.jobHistory.splice(idx, 1);
                })
            },
            // 添加教育经历
            addEducationExperience() {
                this.personalInfo.eduHistory.push({
                    schoolName: '',
                    startTime: '',
                    endTime: '',
                    major: ''
                })
            },
            // 删除教育经历
            delEducationExperience(idx) {
                this.$confirm('确定要删除吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    this.personalInfo.eduHistory.splice(idx, 1);
                })
            },
            submitForm(formName) {
                // console.log(this.personalInfo);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.personalInfo.jobCity = this.personalInfo.jobCity.join("-");
                        this.personalInfo.wishIndustry = this.personalInfo.wishIndustry.join("-");
                        editResume(this.personalInfo).then(res => {
                            if (res.code == 200) {
                                this.$message.success('修改成功');
                                setTimeout(() => {
                                    this.$router.go(-1);
                                }, 500);
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
        },
    };
</script>

<style lang="less" scoped>
    .wrap {
        min-height: 693px;
        background: #f3f3f3;

        .nav_bar {
            padding: 30px;
            margin: 30px 0;
            background-color: #fff;
            border-radius: 4px;
        }

        .form_area {
            position: relative;
            margin: 0 30px;
            padding: 70px 280px 100px;
            border-radius: 4px;
            background-color: #fff;

            ::v-deep.form {
                .avatar_box {
                    position: absolute;
                    left: 120px;
                    top: 70px;

                    .avatar-uploader {
                        display: inline-block;
                        vertical-align: top;
                        position: relative;

                        &:hover {
                            .edit_cover {
                                position: absolute;
                                width: 100%;
                                height: 90px;
                                top: 0;
                                background: #000000;
                                opacity: 0.3;
                                border-radius: 50%;
                                display: block;
                            }

                            .edit_img {
                                display: block;
                            }
                        }

                        .avatar {
                            width: 90px;
                            height: 90px;
                            border-radius: 50%;
                            display: block;
                        }

                        .edit_img {
                            font-size: 14px;
                            font-weight: 500;
                            color: #ffffff;
                            line-height: 14px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            display: none;
                        }
                    }

                    .tips {
                        font-size: 14px;
                        color: #a5a5a5;
                    }
                }

                .el-input__inner,
                .el-textarea__inner {
                    font-family: inherit;
                }

                .el-date-editor,
                .el-select,
                .el-cascader {
                    width: 100%;
                }

                .experience {
                    position: relative;

                    .right {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);

                        .tips {
                            margin-right: 5px;
                            font-size: 14px;
                            color: #cccccc;
                        }

                        .el-icon-circle-plus-outline {
                            font-size: 20px;
                            transition: all 0.3s ease;
                            cursor: pointer;
                            vertical-align: middle;

                            &:hover {
                                color: #1890ff;
                            }
                        }
                    }
                }

                .one_experience {
                    position: relative;
                    margin-left: 70px;
                    padding-right: 30px;
                    border-bottom: dashed 1px #ddd;

                    &:not(:first-child) {
                        margin-top: 20px;
                    }

                    &:last-child {
                        border: none !important;
                    }

                    .el-icon-delete {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        font-size: 20px;
                        color: #ff1818;
                        cursor: pointer;
                    }

                    .time_range {
                        .time_picker {
                            display: inline-block;
                            width: 177px;
                        }

                        .separator {
                            margin: 0 10px;
                        }
                    }
                }

                .submit_btn,
                .reset_btn {
                    width: 97px;
                    height: 40px;

                    &.submit_btn {
                        background: #1890ff;
                        border-color: #1890ff;
                    }
                }
            }
        }
    }
</style>